.e8180d {
  --btn-font-size: var(--line-height-p);
  font-size: var(--btn-font-size);
  background: none;
  border: none;
  border-radius: 50%;
  margin: 0;
  padding: var(--space-sm);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-out, background-color 0.2s ease-out;
  outline-offset: -4px;
}

.e8180d.abfa8d {
  --btn-font-size: var(--line-height-p-small);
}
.e8180d.b41166 {
  --btn-font-size: var(--line-height-h3);
}

.e8180d:active {
  transform: scale(0.9);
}

.be311d {
  color: var(--neutral-on-background-color);
}
.be311d:hover:not(:disabled) {
  background: var(--neutral-hover-color-text);
}
.cd6fdb {
  color: var(--default-on-background-color);
}
.cd6fdb:hover:not(:disabled) {
  background: var(--default-hover-color-text);
}
.dd3695 {
  color: var(--primary-on-background-color);
}
.dd3695:hover:not(:disabled) {
  background: var(--primary-hover-color-text);
}
.a3d6b7 {
  color: var(--secondary-on-background-color);
}
.a3d6b7:hover:not(:disabled) {
  background: var(--secondary-hover-color-text);
}

.e8180d:disabled {
  color: var(--disabled-color);
  cursor: not-allowed;
}
