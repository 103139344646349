.main {
  min-height: 100vh;
}
.main.withAppBar {
  min-height: calc(100vh - 6rem);
}
@media print {
  .main.withAppBar {
    min-height: auto;
    margin: 0;
  }
}
