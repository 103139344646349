.c70b51 {
  margin: 0;
}
.f87745 {
  margin-right: calc(-1 * var(--space-sm));
}
.a1d0f5 {
  margin-left: calc(-1 * var(--space-sm));
}
.b8d119 {
  margin-right: calc(-1 * var(--space-md));
}
.dac5ba {
  margin-left: calc(-1 * var(--space-md));
}
.e33530 {
  margin-top: calc(-1 * var(--space-md));
}
/* Extra Small (xs) 512px */
@media (min-width: 32rem) {
  .d46313 {
    margin-right: calc(-1 * var(--space-sm));
  }
  .b68428 {
    margin-left: calc(-1 * var(--space-sm));
  }
}
@media print {
  .be650d {
    margin-top: 0;
  }
}
