@media print {
  .g .screenOnly {
    display: none;
  }
  .g .paper {
    box-shadow: none !important;
  }
  .g .alwaysBreakAfter {
    page-break-after: always !important;
  }
  .g .table tr > th:first-child,
  .g .table tr > td:first-child {
    padding-left: 0;
  }
  .g .table tr > th:last-child,
  .g .table tr > td:last-child {
    padding-right: 0;
  }
  .g .table th,
  .g .table td {
    font-family: 'Verdana';
  }
  .g .table td {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
  }
  .g .table.dense td,
  .g .table.dense th {
    font-size: 8pt;
  }
  .g .table.dense tr {
    height: 32px;
  }
  .g .twoColumn {
    column-count: 2;
    column-rule: solid 1px var(--neutral-seperator-on-bakcground-color);
  }
  .g .spanAllColumns {
    column-span: all;
  }
}
@media screen {
  .g .printOnly {
    display: none;
  }
}
