.ed0cb2 {
  flex-direction: column;
}
.ed0cb2.d87f30 > * + * {
  margin-top: var(--space-lg);
}
.ed0cb2.e6bf76 > * + * {
  margin-top: var(--space-md);
}
.ed0cb2.f9aaa6 > * + * {
  margin-top: var(--space-sm);
}
.b2eb01 {
  flex-direction: row;
}
.ed0cb2.d43952,
.b2eb01.d43952 {
  padding: var(--space-md);
}
.ed0cb2.ad4001,
.b2eb01.ad4001 {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}
.b2eb01.d87f30 > * + * {
  margin-left: var(--space-lg);
}
.b2eb01.e6bf76 > * + * {
  margin-left: var(--space-md);
}
.b2eb01.f9aaa6 > * + * {
  margin-left: var(--space-sm);
}
.b4518e {
  justify-content: flex-end;
}
.f02aa2 {
  justify-content: space-between;
}
.df99f6 {
  justify-content: flex-start;
}
.aa117c {
  justify-content: center;
}
.c28519 {
  align-items: center;
}
.d235c0 {
  align-items: flex-start;
}
.b07f9c {
  align-items: flex-end;
}
.deac40 {
  flex-grow: 1;
}
.e986eb {
  flex-basis: 50%;
}
.cca093 {
  flex-wrap: wrap;
}
