:root {
  /**
  --scrollbar-width: Is set dynamicly by useScrollbarWidth
    */

  /**
    * Background and surfaces
    */
  --background-color: white;
  --paper-surface-color: white;
  --neutral-surface-color: #f5f5f5;
  --default-surface-color: #ffd4ad; /* light apricot */
  --primary-surface-color: #bff1ff; /* diamond */
  --secondary-surface-color: #ffacaa; /* melon */

  --disabled-color: rgba(0, 0, 0, 0.54);
  --blanket-color: rgba(0, 0, 0, 0.7);

  /**
    * Neutral color
    */
  --neutral-on-background-color: #222222;
  --neutral-light-on-background-color: #dcdcdc;
  --neutral-very-light-on-background-color: #f5f5f5; /* 4% darker than white */
  --neutral-seperator-on-background-color: rgba(224, 224, 224, 1);
  --neutral-hover-color-text: rgba(0, 0, 0, 0.08);
  --neutral-hover-color-filled: rgba(0, 0, 0, 0.08);
  --text-on-neutral-color: rgba(0, 0, 0, 0.87);

  /**
    * Default color
    */
  --default-color: #f29137;
  --default-on-background-color: #af5500; /* Windsor Tan */
  --default-hover-color-text: rgba(242, 145, 55, 0.1);
  --default-hover-color-filled: #f27f13;
  --text-on-default-color: rgba(0, 0, 0, 0.87);

  /**
    * Primary color
    */
  --primary-color: #41bcd8; /* Sea serpent */
  --primary-on-background-color: #00819e; /* CG Blue */
  --primary-hover-color-text: rgba(65, 188, 216, 0.1);
  --primary-hover-color-filled: #0ab2d8;
  --text-on-primary-color: rgba(0, 0, 0, 0.87);

  /**
  * Secondary color
  */
  --secondary-color: #d6312f;
  --secondary-on-background-color: #9b0000; /* USC Cardinal */
  --secondary-hover-color-text: rgba(214, 49, 47, 0.1);
  --secondary-hover-color-filled: #d60300;
  --text-on-secondary-color: white;

  /**
  * Paper surface
  */
  --neutral-on-paper-surface-color: #222222;
  --default-on-paper-surface-color: #af5500;
  --primary-on-paper-surface-color: #00819e;
  --secondary-on-paper-surface-color: #9b0000;
  /**
  * Neutral surface
  */
  --neutral-on-neutral-surface-color: #3e3e3e;
  --default-on-neutral-surface-color: #af5500;
  --primary-on-neutral-surface-color: #004352;
  --secondary-on-neutral-surface-color: #820000;
  /**
  * Default surface
  */
  --neutral-on-default-surface-color: #222222;
  --default-on-default-surface-color: #9a4b00;
  --primary-on-default-surface-color: #006a82;
  --secondary-on-default-surface-color: #9b0000;
  /**
    * Primary surface
    */
  --neutral-on-primary-surface-color: #222222;
  --default-on-primary-surface-color: #a75100;
  --primary-on-primary-surface-color: #00728c;
  --secondary-on-primary-surface-color: #9b0000;
  /**
    * Secondary surface
    */
  --neutral-on-secondary-surface-color: #222222;
  --default-on-secondary-surface-color: #803e00;
  --primary-on-secondary-surface-color: #00576b;
  --secondary-on-secondary-surface-color: #9b0000;

  /**
    * Misc
    */
  --border-radius: 0.25rem;

  --box-shadow-main-color: rgba(0, 0, 0, 0.2);
  --box-shadow-sharpen-color: rgba(0, 0, 0, 0.14);
  --box-shadow-bottom-accent-color: rgba(0, 0, 0, 0.12);

  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-blanket: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;

  --spacer: 0.5rem;
  --space-sm: calc(var(--spacer) * 1);
  --space-md: calc(var(--spacer) * 2);
  --space-lg: calc(var(--spacer) * 3);
  --space-xl: calc(var(--spacer) * 4);

  --btn-line-height-sm: 1rem;
  --btn-line-height-md: 1.5rem;
  --btn-line-height-lg: 2rem;

  /**
   * Font sizes
   */
  --font-size-p-small: 0.8125rem;
  --line-height-p-small: 1rem;
  --font-size-p: 0.875rem;
  --line-height-p: 1.5rem;
  --font-size-p-lead: 1rem;
  --line-height-p-lead: 1.5rem;
  --font-size-h6: 1.125rem;
  --line-height-h6: 1.5rem;
  --font-size-h5: 1.25rem;
  --line-height-h5: 1.5rem;
  --font-size-h4: 1.375rem;
  --line-height-h4: 1.5rem;
  --font-size-h3: 1.5rem;
  --line-height-h3: 2rem;
  --font-size-h2: 1.625rem;
  --line-height-h2: 2rem;
  --font-size-h1: 1.75rem;
  --line-height-h1: 2rem;
}

/* Extra Small (xs) 512px */
@media (min-width: 32rem) {
}

/* Small (sm) 640px */
@media (min-width: 40rem) {
  /* ... */
}

/* Medium (md) 768px */
@media (min-width: 48rem) {
  /* ... */
}

/* Large (lg) 1024px */
@media (min-width: 64rem) {
  /* ... */
}

/* Extra Large (xl) 1280px */
@media (min-width: 80rem) {
  /* ... */
}
