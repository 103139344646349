.cc07c1 {
  background: var(--paper-surface-color, var(--background-color, #fff));
  --neutral-on-background-color: var(--neutral-on-paper-surface-color);
  --default-on-background-color: var(--default-on-paper-surface-color);
  --primary-on-background-color: var(--primary-on-paper-surface-color);
  --secondary-on-background-color: var(--secondary-on-paper-surface-color);
}
.b3ee24 {
  background: var(--neutral-surface-color);
  --neutral-on-background-color: var(--neutral-on-neutral-surface-color);
  --default-on-background-color: var(--default-on-neutral-surface-color);
  --primary-on-background-color: var(--primary-on-neutral-surface-color);
  --secondary-on-background-color: var(--secondary-on-neutral-surface-color);
}
.b46aee {
  background: var(--default-surface-color);
  --neutral-on-background-color: var(--neutral-on-default-surface-color);
  --default-on-background-color: var(--default-on-default-surface-color);
  --primary-on-background-color: var(--primary-on-default-surface-color);
  --secondary-on-background-color: var(--secondary-on-default-surface-color);
}
.d2475a {
  background: var(--primary-surface-color);
  --neutral-on-background-color: var(--neutral-on-primary-surface-color);
  --default-on-background-color: var(--default-on-primary-surface-color);
  --primary-on-background-color: var(--primary-on-primary-surface-color);
  --secondary-on-background-color: var(--secondary-on-primary-surface-color);
}
.b5a6cc {
  background: var(--secondary-surface-color);
  --neutral-on-background-color: var(--neutral-on-secondary-surface-color);
  --default-on-background-color: var(--default-on-secondary-surface-color);
  --primary-on-background-color: var(--primary-on-secondary-surface-color);
  --secondary-on-background-color: var(--secondary-on-secondary-surface-color);
}
.a0f8b0 {
  padding: var(--space-sm);
}
.d34f1a {
  padding: var(--space-md);
}
.e32f73 {
  padding: var(--space-lg);
}
.be8b38 {
  padding: var(--space-xl);
}
.ef8a65 {
  border-radius: var(--border-radius, 0.25rem);
}
.e76ab5 {
  border: 1px solid var(--neutral-on-background-color);
}
.eaa85d {
  border: 1px solid var(--default-on-background-color);
}
.fdd21d {
  border: 1px solid var(--primary-on-background-color);
}
.bc9f38 {
  border: 1px solid var(--secondary-on-background-color);
}
