.bd0473 {
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.f3326a {
  color: var(--neutral-on-background-color);
}
.f8f475 {
  color: var(--default-on-background-color);
}
.a993cd {
  color: var(--primary-on-background-color);
}
.adcfff {
  color: var(--secondary-on-background-color);
}
.db83d8 {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
}
.ba63a7 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
}
.db83d8.a1f8da,
.ba63a7.a1f8da {
  margin-bottom: var(--space-lg);
}
.cee78d {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
}
.e25e86 {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
}
.cee78d.a1f8da,
.e25e86.a1f8da {
  margin-bottom: var(--space-md);
}
.f2e223 {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
}
.b0b978 {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
}
.fa4ee6.fb56ad {
  font-size: var(--font-size-p-lead);
  line-height: var(--line-height-p-lead);
}
.fa4ee6.d10323 {
  font-size: var(--font-size-p-small);
  line-height: var(--line-height-p-small);
}
.fa4ee6 {
  font-size: var(--font-size-p);
  line-height: var(--line-height-p);
}
.d089df {
  font-size: inherit;
  line-height: inherit;
}
.f2e223.a1f8da,
.b0b978.a1f8da,
.fa4ee6.a1f8da {
  margin-bottom: var(--space-sm);
}

/*        fs          lh          ratio
.db83d8       28px  1.75  32px  2     1.14
.ba63a7       26px  1.625 32px  2     1.23
.cee78d       24px  1.5   32px  2     1.33
.e25e86       22px  1.375 32px  1.5   1.1
.f2e223       20px  1.25  24px  1.5   1.2
.b0b978       18px  1.125 24px  1.5   1.333
.fa4ee6.fb56ad   16px  1     24px  1.5   1.5
.fa4ee6        14px  .875  24px  1.5   1.7
.fa4ee6.d10323  12px  .75   24px  1.5   2
*/
