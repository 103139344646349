.c5fa5a {
  position: relative;
  --btn-font-size: var(--font-size-p);
  --btn-line-height: var(--btn-line-height-md);
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  border-radius: var(--border-radius);
  margin: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-out, background-color 0.2s ease-out;
  text-transform: uppercase;
  letter-spacing: 0.057em;
  outline-offset: -4px;
}
.c5fa5a:disabled {
  cursor: not-allowed;
}
.c5fa5a:active {
  transform: scale(0.9);
}
.c5fa5a svg {
  font-size: var(--btn-line-height);
}
.c5fa5a.f908c5 svg {
  margin-right: calc(0.5 * var(--btn-font-size));
}
.c5fa5a.f84a51 svg {
  margin-left: calc(0.5 * var(--btn-font-size));
}

.f40fcf {
  --btn-font-size: var(--font-size-p-small);
  --btn-line-height: var(--btn-line-height-sm);
}
.a6c461 {
  --btn-font-size: var(--font-size-p-lead);
  --btn-line-height: var(--btn-line-height-lg);
}

/* Variant: text */
.de5fd7 {
  background: none;
  border: none;
  padding: var(--space-sm) var(--space-md);
}
.de5fd7.b481be {
  color: var(--neutral-on-background-color);
}
.de5fd7.b481be:hover:not(:disabled) {
  background: var(--neutral-hover-color-text);
}
.de5fd7.ad7832 {
  color: var(--default-on-background-color);
}
.de5fd7.ad7832:hover:not(:disabled) {
  background: var(--default-hover-color-text);
}
.de5fd7.d955a4 {
  color: var(--primary-on-background-color);
}
.de5fd7.d955a4:hover:not(:disabled) {
  background: var(--primary-hover-color-text);
}
.de5fd7.d430f5 {
  color: var(--secondary-on-background-color);
}
.de5fd7.d430f5:hover:not(:disabled) {
  background: var(--secondary-hover-color-text);
}
.de5fd7:disabled {
  color: var(--disabled-color);
}

/* Variant: filled */
.a7df06 {
  border: none;
  padding: var(--space-sm) var(--space-md);
}
.a7df06:not(:disabled) {
  box-shadow: 0px 1px 5px 0px var(--box-shadow-main-color),
    0px 2px 2px 0px var(--box-shadow-sharpen-color),
    0px 3px 1px -2px var(--box-shadow-bottom-accent-color);
}
.a7df06.b481be {
  background: var(--neutral-very-light-on-background-color);
  color: var(--text-on-neutral-color);
}
.a7df06.b481be:hover:not(:disabled) {
  background: var(--neutral-hover-color-filled);
}
.a7df06.ad7832 {
  background: var(--default-color);
  color: var(--text-on-default-color);
}
.a7df06.ad7832:hover:not(:disabled) {
  background: var(--default-hover-color-filled);
}
.a7df06.d955a4 {
  background: var(--primary-color);
  color: var(--text-on-primary-color);
}
.a7df06.d955a4:hover:not(:disabled) {
  background: var(--primary-hover-color-filled);
}
.a7df06.d430f5 {
  background: var(--secondary-color);
  color: var(--text-on-secondary-color);
}
.a7df06.d430f5:hover:not(:disabled) {
  background: var(--secondary-hover-color-filled);
}
.a7df06:disabled::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius);
}

/* Variant: outlined */
.a1b56e {
  padding: calc(var(--space-sm) - 1px) calc(var(--space-md) - 1px);
  background: none;
}
.a1b56e.b481be {
  border: 1px solid var(--neutral-on-background-color);
  color: var(--neutral-on-background-color);
}
.a1b56e.b481be:hover:not(:disabled) {
  background: var(--neutral-hover-color-text);
}
.a1b56e.ad7832 {
  border: 1px solid var(--default-on-background-color);
  color: var(--default-on-background-color);
}
.a1b56e.ad7832:hover:not(:disabled) {
  background: var(--default-hover-color-text);
}
.a1b56e.d955a4 {
  border: 1px solid var(--primary-on-background-color);
  color: var(--primary-on-background-color);
}
.a1b56e.d955a4:hover:not(:disabled) {
  background: var(--primary-hover-color-text);
}
.a1b56e.d430f5 {
  border: 1px solid var(--secondary-on-background-color);
  color: var(--secondary-on-background-color);
}
.a1b56e.d430f5:hover:not(:disabled) {
  background: var(--secondary-hover-color-text);
}

.a1b56e:disabled {
  color: var(--disabled-color);
}
