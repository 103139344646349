.a2f76c,
.aaca06 {
  --link-font-size: var(--font-size-p);
  --link-line-height: var(--line-height-p);
  font-size: var(--link-font-size);
  line-height: var(--link-line-height);
  text-decoration: none;
  letter-spacing: 0.057em;
}
.aaca06 {
  color: var(--neutral-on-background-color);
}
.a2f76c {
  color: var(--primary-on-background-color);
}
.a2f76c:hover,
.aaca06:hover {
  text-decoration: underline;
}

.ee1bd4 {
  --link-font-size: var(--font-size-p-small);
  --link-line-height: var(--line-height-p-small);
}
.ef3df0 {
  --link-font-size: var(--font-size-p-lead);
  --link-line-height: var(--line-height-p-lead);
}
