.d667f1 {
  padding: var(--space-md);
}
.cf0526 {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}
.a97ef5 {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}
.a8cdd5 {
  padding-top: var(--space-md);
}
.b83945 {
  padding: 0;
}
@media print {
  .b3c29e {
    padding: 0;
  }
}
