.appBar {
  position: sticky;
  top: 0px;
  z-index: var(--zindex-sticky);
}
.appBar,
.subBar {
  box-shadow: inset 0px -1px 0px 0px var(--neutral-seperator-on-background-color);
  height: 3rem;
}
.subBar {
  display: none;
}
.navLink,
.crumbLink {
  padding: 0 var(--space-sm);
  line-height: 3rem;
}
.navLink {
  display: none;
}
.navLink.active {
  /* text-decoration: underline; */
  --primary-on-background-color: var(--primary-on-paper-surface-color);
  background: var(--paper-surface-color);
  box-shadow: inset 0px -1px 0px 0px var(--neutral-seperator-on-background-color);
}
.blanket {
  top: 3rem !important;
}
.asideMenu {
  position: fixed;
  top: 3rem;
  z-index: var(--zindex-modal);
}
.asideMenu,
.asideMenuContentContainer {
  height: calc(100vh - 3rem);
  width: 15rem;
}
.asideMenuContentContainer {
  padding: var(--space-md);
  overflow-y: auto;
}
.asideMenuContentContainer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.asideMenuContentContainer .subTree {
  padding-top: var(--space-sm);
  padding-left: var(--space-md);
}
/* Extra Small (xs) 512px */
@media (min-width: 32rem) {
  .menuButton,
  .crumbSpan,
  .crumbLink,
  .blanket,
  .asideMenu {
    display: none;
  }
  .navLink {
    display: inline-block;
  }
  .subBar {
    display: flex;
  }
  .appBar,
  .subBar {
    /* padding-left: var(--space-sm); */
  }
}
/* Small (sm) 640px */
@media (min-width: 40rem) {
}
@media print {
  .appBar,
  .subBar {
    display: none;
  }
}
