.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  padding: 2rem;
}
.main > * + * {
  margin-top: 2rem;
}
.main h1,
.main p {
  text-align: center;
}
.main svg {
  height: 10rem;
}
.paper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 500px;
  min-height: 221px;
}
.main .paper p {
  text-align: left;
}
.form {
  display: flex;
  flex-direction: column;
}
.main .form > * + * {
  margin-top: 18px;
}
.main .tryAgainButton {
  margin-left: auto;
}
.main .tryAgainLink {
  --btn-font-size: 1rem;
  --btn-line-height: 1.5rem;
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  text-decoration: none;
  color: var(--primary-on-background-color);
  letter-spacing: 0.057em;
}
.main .tryAgainLink:hover {
  text-decoration: underline;
}
.paper p {
  flex-grow: 1;
}
